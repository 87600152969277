/* html, body {
    height: 100%;
  }
  
  .wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top:40px;
  }
  
  .button,.button2 {
    cursor: pointer;
  }

  .button {
    min-width: 200px;
    min-height: 200px;
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: #313133;
    background: #4FD1C5;
  background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgba(79,209,197,1) 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 12px 12px 24px rgba(79,209,197,.64);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
    }
  
  button::before {
  content: '';
    border-radius: 1000px;
    min-width: calc(200px + 12px);
    min-height: calc(200px + 12px);
    border: 6px solid #00FFCB;
    box-shadow: 0 0 60px rgba(0,255,203,.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  }
  
  .button:hover, .button:focus {
    color: #313133;
    transform: translateY(-6px);
  }
  
  button:hover::before, button:focus::before {
    opacity: 1;
  }
  
  button::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }
  
  button:hover::after, button:focus::after {
    animation: none;
    display: none;
  }
  
  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }





  .button2 {
    min-width: 200px;
    min-height: 200px;
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: #313133;
    background: red;
  background: linear-gradient(90deg, rgb(230, 129, 129) 0%, rgb(209, 79, 79) 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 12px 12px 24px rgba(79,209,197,.64);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
    }
  
  button2::before {
  content: '';
    border-radius: 1000px;
    min-width: calc(200px + 12px);
    min-height: calc(200px + 12px);
    border: 6px solid red;
    box-shadow: 0 0 60px rgba(255, 0, 0, 0.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  }
  
  .button2:hover, .button2:focus {
    color: #313133;
    transform: translateY(-6px);
  }
  
  button2:hover::before, button2:focus::before {
    opacity: 1;
  }
  
  button2::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }
  
  button2:hover::after, button2:focus::after {
    animation: none;
    display: none;
  }
  
  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  } */

.countrySelection{
  padding:.15rem .75rem !important;
  border:1px solid #ced4da !important;
  border-bottom-width: 1px !important;
}
.countrySelection:hover{
  border-bottom-width: 1px !important;
}
.countrySelection:focus{
  border-bottom-width: 1px !important;
}
.countrySelection::before{
  border-bottom:1px !important;
}
.countrySelection:hover::before{
  border-bottom-width:1px  !important;
}
.countrySelection::before:active{
  border-bottom-width: 1px !important;
}