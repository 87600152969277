.audio-preview-container{
    position: absolute;
    bottom: 76px;
     left: 32px;
}

.audio-preview-wrap{
    display: flex;
    width: 600px;
}

.audio-wrap-media{
    display: flex; 
    flex-wrap: wrap;
}

.remove-icon-audio{
    cursor: pointer;
    position: absolute;
    right: 2px;
    background: #fff;
    top: 2px;
    border-radius: 50%;
    z-index:2
}
.audio-container{
    padding:6px;
    position: relative;
}
.uploading-audio{
    border-radius: 6px;
    z-index: 999999;
}