@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 90%;
  }
}

.load-progress {
  vertical-align: bottom;
  height: 0;

  &.bar {
    width: 100%;
    background: cadetblue;
  }

  &.is-loading {
    height: 6px;
    animation: loading;
  }

  &.is-done {
    height: 6px;
    transition: width 1s;
    width: 100% !important;
  }
}

.filetypeview {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 8px 12px;
  min-width: 60px;
  text-align: center;

  & a{
    cursor: pointer;
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
.imagetypeview{
  img{
    border-radius: 6px;
  }
}
.videotypeview{

}
