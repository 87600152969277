/* ################################
        RentModal CSS
################################ */
.modal_body {
  overflow: scroll;
  overflow-x: hidden;
  height: 70vh;
}

#list_text {
  font-size: 17px;
}

#input_text {
  font-size: 17px;
  padding-top: 9px;
}
#searchbox {
  max-width: 360px;
  margin: auto;
  padding-top: 4vh;
}
#searchbox > .input-icons > input {
  display: flex;
  text-align: left;
  padding-left: 20px;
  margin-right: 10px;
}
input[type="search"]::-webkit-search-cancel-button {
  color: green;
  height: 10px;
  width: 10px;

  border-radius: 50%;
}
#h5 {
  font-size: 17px;
}

#country_item {
  max-width: 500px;
}
#number {
  font-size: 23px;
}

#continue {
  width: 100%;
}

#accordian {
  height: 7vh;
  width: 90%;
  justify-content: center;
  margin-top: 3vh;
}

/* .mdl_header{
    background-color: rgb(62, 161, 185) !important;
} */

@media (max-width: 410px) {
  #accordian {
    width: 330px;
  }
  #tt {
    font-size: 14px;
  }
}

.radio {
  margin-left: 30px;
}

.numbert {
  font-size: 16px;
}

.s4 {
  padding-top: 4px;
}

#input_field {
  padding: 5px;
}
/* ############################
        PaymentModal CSS
#############################  */

#creditt {
  width: 100%;
}

/* #first{
    height: 5vh;
    width: 220px;
    justify-content: center;
    margin-top: 3vh;
}

#secound{
 height: 5vh;
 width: 200;
 justify-content: center;
 margin-top: 3vh;
} */

#third {
  height: 4vh;
  width: 80px;
  justify-content: center;
  margin-top: 5vh;
}
/* .s1{
     padding-top: 3px;
     font-family: 'Times New Roman', Times, serif;
     font-size: 16px;
 }

 .s2{
    padding-top: 3px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
} */

.s3 {
  /* padding-bottom: 3px; */
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
}

/* #payable{
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px;
} */

.call_sms {
  font-size: 16px;
  font-weight: bold;
}

.MuiFormControlLabel-label {
  font-size: 15px !important;
}

.modal-head > * {
  font-size: 20px !important;
}

.modal-body {
  padding-top: 10px;
}

/* ############## */
/* Jitsi Modal  */
/* ############## */

.textfield {
  margin-left: 23% !important;
  margin-top: 10px;
  margin-bottom: 12px !important;
  width: 61% !important;
}

.inputbox {
  width: 50% !important;
}

.modalBody {
  padding: 0 !important;
}

.error_text {
  color: red !important;

}
.closeIcon{
  padding: 6px !important;
  cursor: pointer !important;
}

.textError{
  color:red;
  margin-left: 16% !important;
  border: none;
  width: 70%;
  outline: none;
  background-color: white;
  padding-bottom: 1%;
 
}