.loading
{
  position:fixed;
  top:48%;
  left:48%;
  color: #000;  
  z-index: 999;
}

.loader{
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0.5;
    display: block;
    cursor: wait;
    background-color:rgba(41, 35, 35, 0.5);
    /* background-color:white; */
    z-index: 998;
  }

  .loader2{
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: block;
    cursor: wait;
    background-color:white;
    z-index: 998;
  }