@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import "Vars";
@import "Typography";
@import "Button";
@import "Modal";
@import "Tab";
@import "Color";
@import "Avatar";
@import "DropdownMenu";
@import "Membership";
@import "Navigation";
@import "Dark";
@import "Other";
@import "Rtl";

.layout {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;

  .content {
    display: flex;
    flex: 1;

    .sidebar-group {
      display: flex;
      flex-direction: column;
		overflow-y: auto;
		// -webkit-overflow-y: touch;
      .sidebar {
        &:not(.active) {
          display: none;
        }

        .sidebar-title {
          font-size: 22px;
        }

        // background: white;
        // border-radius: 5px;
        // overflow: hidden;
        width: 350px;
        display: flex;
        flex-direction: column;
        // flex: 1;

        & > header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 30px;
          height: 80px;
          font-weight: 600;
          border-radius: 0;

          ul {
            margin-bottom: 0;

            li {
              margin-bottom: 0;
            }
          }
        }

        & > form {
          padding: 17px 30px;
          margin-bottom: 1rem;

          .form-control {
            border-radius: 5px;
            height: auto;
            border: 1px solid $style-color;
            padding: 10px 15px;
          }
        }

        & .sidebar-body {
          flex: 1;
          overflow: auto;
        }

        .list-group-item {
          padding: 17px 30px;
          display: flex;
          cursor: pointer;

          &.open-chat {
            background-color: $style-color + 10;

            .users-list-body {
              p {
                font-weight: 600;
                color: black + 100;
              }
            }
          }

          figure {
            margin-right: 1rem;
          }

          .users-list-body {
            flex: 1;
            position: relative;
            min-width: 0px;
            display: flex;

            & > div:first-child {
              min-width: 0;
              display: flex;
              flex-direction: column;
              flex: 1;
            }

            h5 {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: $default-font-size + 2;
              font-weight: 500;
              margin-bottom: 0.2rem;
            }

            p {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-bottom: 0;
              color: black + 150;
            }

            .users-list-action {
              right: 0;
              position: absolute;

              [data-toggle="dropdown"] {
                i {
                  font-size: $default-font-size + 4;
                }
              }

              .new-message-count {
                width: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 0;
                font-size: $default-font-size - 1;
                height: 23px;
                background-color: $color-primary;
                color: white;
                border-radius: 50%;
                margin-left: auto;
              }

              .action-toggle {
                display: none;
                text-align: right;
                position: absolute;
                right: 0;

                .dropdown {
                  & > span {
                    color: $color-primary;
                  }
                }
              }
            }
          }

          &:hover {
            .users-list-action {
              .new-message-count {
                display: none;
              }

              .action-toggle {
                display: block;
              }
            }
          }
        }
      }
    }

    .chat {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 1px solid $style-color;
      border-left: 1px solid $style-color;

      .chat-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $style-color;
        padding: 17px 30px;

        .chat-header-user {
          display: flex;
          //     align-items: center;
          align-items: flex-start;
          height: 60px;

          img {
            border-radius: 50%;
          }
          span {
            height: 8px;
            width: 8px;
            background-color: green;
            border-radius: 50%;
            display: inline-block;
            margin-left: 5px;
          }

          figure {
            margin-right: 1rem;
          }

          h5 {
            font-size: $default-font-size + 2;
            font-weight: 600;
            margin-bottom: 0;
            line-height: 1;
          }
          .infoSection {
            margin-left: 5px;
          }
        }

        .chat-header-action {
          ul {
            margin-bottom: 0;

            & > li {
              & > a {
                font-size: $default-font-size + 2;
              }
            }
          }
        }
      }
    }
  }
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	display: flex;

	.content {
		display: flex;
		flex: 1;

		.sidebar-group {

			display: flex;
			flex-direction: column;

			.sidebar {
				&:not(.active) {
					display: none;
				}

				.sidebar-title {
					font-size: 22px;
				}

				// background: white;
				// border-radius: 5px;
				// overflow: hidden;
				// width: 350px;
				// display: flex;
				// flex-direction: column;
				// flex: 1;

				& > header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 30px;
					height: 80px;
					font-weight: 600;
					border-radius: 0;

					ul {
						margin-bottom: 0;

						li {
							margin-bottom: 0;
						}

						
					}
				}

				& > form {
					padding: 17px 30px;
					margin-bottom: 1rem;

					.form-control {
						border-radius: 5px;
						height: auto;
						border: 1px solid $style-color;
						padding: 10px 15px;
					}
				}

				& .sidebar-body {
					flex: 1;
					overflow: auto;
				}

				.list-group-item {
					padding: 17px 30px;
					display: flex;
					cursor: pointer;

					&.open-chat {
						background-color: $style-color + 10;

						.users-list-body {
							p {
								font-weight: 600;
								color: black + 100
							}
						}
					}

					&.open-sms {
						background-color: $style-color + 10;

						.users-list-body {
							p {
								font-weight: 600;
								color: black + 100
							}
						}
					}

					figure {
						margin-right: 1rem;
					}

					.users-list-body {
						flex: 1;
						position: relative;
						min-width: 0px;
						display: flex;

						& > div:first-child {
							min-width: 0;
							display: flex;
							flex-direction: column;
							flex: 1;
						}

						h5 {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: $default-font-size + 2;
							font-weight: 500;
							margin-bottom: .2rem;
						}

						p {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							margin-bottom: 0;
							color: black + 150
						}

						.users-list-action {
							right:0;
							position: absolute;

							[data-toggle="dropdown"] {
								i {
									font-size: $default-font-size + 4;
								}
							}

							.new-message-count {
								width: 23px;
								display: flex;
								align-items: center;
								justify-content: center;
								line-height: 0;
								font-size: $default-font-size - 1;
								height: 23px;
								background-color: $color-primary;
								color: white;
								border-radius: 50%;
								margin-left: auto;
							}

							.action-toggle {
								display: none;
								text-align: right;
								position: absolute;
								right: 0;

								.dropdown {
									& > span {
										color: $color-primary;
									}
								}
							}
						}
					}

					&:hover {
						.users-list-action {
							.new-message-count {
								display: none;
							}

							.action-toggle {
								display: block;
							}
						}
					}
				}
			}
		}

		.chat {
			flex: 1;
			display: flex;
			flex-direction: column;
			border-right: 1px solid $style-color;
			border-left: 1px solid $style-color;

			.chat-header {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid $style-color;
				padding: 17px 30px;

				.chat-header-user {
					display: flex;
					align-items: center;

					figure {
						margin-right: 1rem;
					}

					h5 {
						font-size: $default-font-size + 2;
						font-weight: 600;
						margin-bottom: 0;
						line-height: 1;
					}
				}

				.chat-header-action {
					ul {
						margin-bottom: 0;

						& > li {
							& > a {
								font-size: $default-font-size + 2;
							}
						}
					}
				}
			}

			.chat-body {
				flex: 1;
				padding: 30px;

				&:not(.no-message) {
					overflow: auto;
				}

				&.no-message {
					user-select: none;

					.no-message-container {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						height: 100%;
						text-align: center;

						svg {
							width: 50px;
							height: 50px;
							stroke: $color-primary;
							fill: $style-color;
						}

						p {
							font-size: $default-font-size + 2;
							color: black + 130
						}
					}
				}

				.messages {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.message-item {
						max-width: 75%;
						margin-bottom: 3rem;

						&:last-child {
							margin-bottom: 0;
						}

						.message-avatar {
							display: flex;
							align-items: center;
							margin-bottom: 1rem;

							.avatar {
								margin-right: 1rem;
							}

							h5 {
								font-size: 15px;
								margin-bottom: 0;
							}

							.time {
								color: #828282;
								margin-top: 2px;
								font-style: italic;
								font-size: 12px;

								.ti-double-check {
									margin-left: 5px;

									&:before, &:after {
										content: "✔";
									}

									&:after {
										margin-left: -2px;
									}
								}
							}
						}

						.message-content {
							background: $style-color + 5;
							border-radius: 7px;
							padding: 10px 20px;

							&.message-file {
								display: block;
								word-break: break-word;
								& span{
									& a{
										overflow-wrap: break-word;
                                        max-width: 350px;
									}
								}

								.file-icon {
									font-size: $default-font-size + 10;
									margin-right: 1rem;
									color: black + 130;
								}

								ul {
									margin-top: 3px;
									margin-bottom: 0;

									li.list-inline-item {
										margin-left: 0;
										margin-right: .5rem;
									}
								}
							}
						}

						&.outgoing-message {
							margin-left: auto;

							.message-avatar {
								justify-content: flex-end;
							}

							.message-content {
								background-color: $color-primary;
								color: white;
							}
						}
					}

					.messages-divider {
						width: 100%;
						max-width: 100%;
						position: relative;

						&:before {
							content: attr(data-label);
							display: block;
							position: absolute;
							top: -8px;
							letter-spacing: .5px;
							font-size: 11px;
							padding: 2px 8px;
							border-radius: 3px;
							background-color: $style-color;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			.chat-footer {
				border-top: 1px solid $style-color;
				padding: 17px 30px;
				position: relative;

				form {
					display: flex;
					align-items: center;

					.form-buttons {
						display: flex;

						.btn {
							margin-left: 1rem;
						}
					}
				}
			}

			& + .sidebar-group {
				margin-right: 0px;

				.sidebar {
					margin-right: 0px;
				}
			}
		}

		.sms {
			flex: 1;
			display: flex;
			flex-direction: column;
			border-right: 1px solid $style-color;
			border-left: 1px solid $style-color;

			.sms-header {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid $style-color;
				padding: 17px 30px;

				.sms-header-user {
					display: flex;
					align-items: center;

					figure {
						margin-right: 1rem;
					}

					h5 {
						font-size: $default-font-size + 2;
						font-weight: 600;
						margin-bottom: 0;
						line-height: 1;
					}
				}

				.sms-header-action {
					ul {
						margin-bottom: 0;

						& > li {
							& > a {
								font-size: $default-font-size + 2;
							}
						}
					}
				}
			}

			.sms-body {
				flex: 1;
				padding: 30px;

				&:not(.no-message) {
					overflow: auto;
				}

				&.no-message {
					user-select: none;

					.no-message-container {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						height: 100%;
						text-align: center;

						svg {
							width: 50px;
							height: 50px;
							stroke: $color-primary;
							fill: $style-color;
						}

						p {
							font-size: $default-font-size + 2;
							color: black + 130
						}
					}
				}

				.messages {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					.message-item {
						max-width: 75%;
						margin-bottom: 3rem;

						&:last-child {
							margin-bottom: 0;
						}

						.message-avatar {
							display: flex;
							align-items: center;
							margin-bottom: 1rem;

							.avatar {
								margin-right: 1rem;
							}

							h5 {
								font-size: 15px;
								margin-bottom: 0;
							}

							.time {
								color: #828282;
								margin-top: 2px;
								font-style: italic;
								font-size: 12px;

								.ti-double-check {
									margin-left: 5px;

									&:before, &:after {
										content: "✔";
									}

									&:after {
										margin-left: -2px;
									}
								}
							}
						}

						.message-content {
							background: $style-color + 5;
							border-radius: 7px;
							padding: 10px 20px;

							&.message-file {
								display: flex;
								word-break: break-word;

								.file-icon {
									font-size: $default-font-size + 10;
									margin-right: 1rem;
									color: black + 130;
								}

								ul {
									margin-top: 3px;
									margin-bottom: 0;

									li.list-inline-item {
										margin-left: 0;
										margin-right: .5rem;
									}
								}
							}
						}

						&.outgoing-message {
							margin-left: auto;

							.message-avatar {
								justify-content: flex-end;
							}

							.message-content {
								background-color: $color-primary;
								color: white;
							}
						}
					}

					.messages-divider {
						width: 100%;
						max-width: 100%;
						position: relative;

						&:before {
							content: attr(data-label);
							display: block;
							position: absolute;
							top: -8px;
							letter-spacing: .5px;
							font-size: 11px;
							padding: 2px 8px;
							border-radius: 3px;
							background-color: $style-color;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			.sms-footer {
				border-top: 1px solid $style-color;
				padding: 17px 30px;

				form {
					display: flex;
					align-items: center;

					.form-buttons {
						display: flex;

						.btn {
							margin-left: 1rem;
						}
					}
				}
			}

			& + .sidebar-group {
				margin-right: 0px;

				.sidebar {
					margin-right: 0px;
				}
			}
		}
	}
}

.tooltip {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background-color: rgba(black, 0.5);
  }
}

.kVEayH {
  background: $color-primary !important;
  padding: 0;
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  box-shadow: none !important;
}

.fKLrJo {
  background: $color-primary !important;
}

.call {
  text-align: center;

  .modal-content {
    .modal-body {
      padding: 10px;
    }
  }

  .action-button {
    margin-top: 3rem;

    .btn {
      width: 70px;
      height: 70px;
      margin: 0 15px;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.profileVcard {
	margin-right: 50px;
}

.downloadIcon {
	height: 20px;
}

.profileImageSms {
	width: 50px;
	margin-right: 20px;
	height: 50px;
}

.contactDetailsBottomIcons {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
	width: 100px;
}

.contactDetailsBottomSingleIcons {
	height: 20px;
}

.addContactModalBody {
	margin-top: 10px;
}

.emojiBoxOpen {
	position: absolute;
	bottom: 71px;
}

.contactModalPhoneIcon {
	width: 30px;
	height: 50px;
	fill: #8d8f8e; 
	color: #8d8f8e; 
	margin-left: 7px;
}

.callLogPhoneIcon {
	width: 20px;
	height: 17px;
	fill: #8d8f8e; 
	color: #8d8f8e; 
	margin-left: 7px;
}

.infoCallLog {
	margin-left: 50px;
}

.callLogsInfo {
	display: flex;
	justify-content: space-between;
}

.callLogText {
	padding-left: 10px;
	font-size: 12px;
}

.avtarCallLogs {
	width: auto;
}

.infoImage {
	margin-left: 30px;
}

.callLogsMain {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tabPaneIframe {
	display: flex; 
	align-items: center; 
	height: 30em; 
	width: 100%;
}

.dropDownMenuNavigation {
	width: 20%;
}

.dropDownItemNavigation {
	text-overflow: ellipsis;
	overflow: hidden;
}

.ChatsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.btn.btn-primary {
  background: #4faea8 !important;
  border-color: #4faea8 !important;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover,
a.btn:not(:disabled):not(.disabled):hover[href="#next"],
a.btn:not(:disabled):not(.disabled):hover[href="#previous"],
.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href="#next"],
a.btn:not(:disabled):not(.disabled):focus[href="#previous"],
.btn.btn-primary:not(:disabled):not(.disabled):active,
a.btn:not(:disabled):not(.disabled):active[href="#next"],
a.btn:not(:disabled):not(.disabled):active[href="#previous"] {
  background: #479c97 !important;
  border-color: #479c97 !important;
}

.color1 {
  color: #4faea8 !important;
}

@import "Responsive";
