.image-preview-container{
    position: absolute;
    bottom: 76px;
    left: 32px;
}

.image-preview-wrap{
    display: flex;
    width: 600px;
}

.image-wrap-media{
    display: flex; 
    flex-wrap: wrap;
    
}

.remove-icon{
    cursor: pointer;
    position: absolute;
    right: 2px;
    background: #fff;
    top: 2px;
    border-radius: 50%;
}
.image-container{
    padding:6px;
    position: relative;
    background-color: #ebebeb;
    border-radius: 8px;
}
.uploading-img{
    border-radius: 6px;

        height:120px;
        width:120px;

}
@media screen and (max-width: 900px) {
    .image-preview-wrap{
        width: 600px;
    }
}

@media screen and (max-width: 700px) {
    .image-preview-wrap{
        width: 460px;
    }
}

@media screen and (max-width: 560px) {
    .image-preview-wrap{
        width: 390px;
    }
}

@media screen and (min-width: 1360px) {
    .image-preview-wrap{
        width: 700px;
    }
}
