.imgWrapModal{
    & .imageContainer{
        position: relative;
        width: 100%;
        margin: auto;

        @media screen and (max-width: 900px) {
            width: 77%;
        }

        @media screen and (max-width: 600px) {
            width: 66%;
        }

        @media screen and (max-width: 400px) {
            width: 44%;
        }

        & .closeWrap{
            position: absolute;
            top:0;
            right:0;
            margin: -6px;
            border: 2px solid #7f7f7f;
            border-radius: 50%;
            background-color: #000;
            color: #fff;
            padding:2px;
            cursor:pointer;
            
        }
    }
}