.video-preview-container{
    position: absolute;
    bottom: 76px;
     left: 32px;
}

.video-preview-wrap{
    display: flex;
    width: 600px;
}

.video-wrap-media{
    display: flex; 
    flex-wrap: wrap;
    background-color: #ebebeb;
    border-radius: 8px;
}

.remove-icon-video{
    cursor: pointer;
    position: absolute;
    right: 2px;
    background: #fff;
    top: 2px;
    border-radius: 50%;
    z-index:2
}
.image-container{
    padding:6px;
    position: relative;
}
.uploading-video{
    border-radius: 6px;
    z-index: 999999;
}