$header-height: 60px;

$style-color: white - 25;

$color-primary: #0a80ff;
$color-secondary: white - 150;
$color-success: #0abb87;
$color-info: #00bcd4;
$color-light: white - 25;
$color-danger: #fd397a;
$color-warning: #ffb822;
$color-dark: #3a3f51;

$color-primary-bright: #c0c5e4;
$color-secondary-bright: #ededed;
$color-success-bright: #cef5ea;
$color-info-bright: #e1efff;
$color-danger-bright: #fcd0df;
$color-warning-bright: #ffe6cc;
$color-dark-bright: #d4d5d8;

$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-google: #db4437;
$color-pinterest: #bd081c;
$color-linkedin: #0077b5;
$color-tumblr: #35465c;
$color-instagram: #3f729b;
$color-dribbble: #ea4c89;
$color-youtube: #cd201f;
$color-whatsapp: #43d854;
$color-github: #00405d;
$color-behance: #1769ff;
$color-skype: #00aff0;
$color-yahoo: #410093;
$default-avatar-size: 2.3rem;
$default-font-size: 14px;
