@media (max-width: 1200px) {
    body {
        &.navigation-open {
            &:after {
                content: '';
                display: block;
                position: fixed;
                background: rgba(black, .5);
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                z-index: 1020;
            }

            .navigation {
                display: block;
            }
        }
        &.navigation-open1 {
            &:after {
                content: '';
                display: block;
                position: fixed;
                background: rgba(black, .5);
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                z-index: 1020;
            }

            .navigation {
                display: block;
            }
        }
    }
    .navigation {
        display: none;
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        z-index: 1021;
    }
    .chat {
        .no-message {
            display: none;
        }
    }
    .sms {
        .no-message {
            display: none;
        }
    }
    .navigation + .sidebar-group {
        flex: 1;

        .sidebar {
            width: 100% !important;
        }
    }
    .chat {
        display: none !important;

        &.open {
            display: flex !important;
            position: fixed;
            background: white;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
        }

        & + .sidebar-group {
            position: fixed;
            right: -50%;
            top: 0;
            bottom: 0;
            width: 30%;
            opacity: 0;
            transition: all .2s;
            box-shadow: 25px 0px 30px 5px black;
            visibility: hidden;
            z-index: 1021;

            .sidebar {
                width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                border-radius: 0 !important;
            }

            &.mobile-open {
                opacity: 1;
                right: 0;
                visibility: visible;
                background-color: #fff;
            }
        }
    }

    .sms {
        // display: none !important;

        &.open {
            display: flex !important;
            position: fixed;
            background: white;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
        }

        & + .sidebar-group {
            position: fixed;
            right: -50%;
            top: 0;
            bottom: 0;
            width: 30%;
            opacity: 0;
            transition: all .2s;
            box-shadow: 25px 0px 30px 5px black;
            visibility: hidden;
            z-index: 1021;

            .sidebar {
                width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                border-radius: 0 !important;
            }

            &.mobile-open {
                opacity: 1;
                right: 0;
                visibility: visible;
            }
        }
    }

    .layout .content {
        .chat .chat-footer {
            padding-bottom: 17px;
        }

        .sms .sms-footer {
            padding-bottom: 17px;
        }

        .sidebar-group .sidebar .list-group-item .users-list-body {
            .users-list-action {
                display: flex;
                flex-direction: row-reverse;

                .new-message-count {
                    margin-left: 10px;
                }

                .action-toggle {
                    top: 25px;
                    display: block;
                }
            }
        }

    }
    body.modal-open {
        .sidebar-group {
            right: 0;
        }
    }
}

@media screen and (max-width: 992px) {
    body:not(.rtl), body {
        .sidebar-group {
            width: 45%;
        }
    }
}

@media screen and (max-width: 768px) {

    body:not(.rtl), body {
        .sidebar-group {
            width: 75%;

            .sidebar {
                .list-group-item {
                    .users-list-body h5 {
                        font-size: $default-font-size + 7 !important;
                    }
                }
            }
        }
    }

    .layout {
        .content {

            .chat {
                .chat-header {
                    display: block !important;
                    padding-bottom: 15px !important;

                    .chat-header-user {
                        h5 {
                            font-size: $default-font-size + 5 !important;
                        }
                    }

                    .chat-header-action {
                        margin-top: 15px;
                    }
                }

                .chat-body {
                    .messages {
                        .message-item {
                            max-width: 100% !important;
                        }
                    }
                }
            }

            .sms {
                .sms-header {
                    display: block !important;
                    padding-bottom: 15px !important;

                    .sms-header-user {
                        h5 {
                            font-size: $default-font-size + 5 !important;
                        }
                    }

                    .sms-header-action {
                        margin-top: 15px;
                    }
                }

                .sms-body {
                    .messages {
                        .message-item {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    body:not(.rtl), body {
        .sidebar-group {
            width: 100%;
        }
    }

    body.form-membership {
        padding: 20px;

        .form-wrapper {
            padding-right: 20px;
            padding-left: 20px;
            width: 100% !important;
            margin-bottom: 20px;
        }
    }
}

