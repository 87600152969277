.document-preview-container{
    position: absolute;
    bottom: 76px;
     left: 32px;
}

.document-preview-wrap{
    display: flex;
    width: 600px;
}

.document-wrap-media{
    display: flex; 
    flex-wrap: wrap;
    background-color: #ebebeb;
    border-radius: 8px;
}

.remove-icon-document{
    cursor: pointer;
    position: absolute;
    right: 2px;
    background: #fff;
    top: 2px;
    border-radius: 50%;
    z-index:2;
    &.file{
        right:-22px;
        top: -12px;
    }
}
.file-container{
    padding:6px;
    position: relative;
    & .fileTitle{
        margin: 8px 12px;
    }
}
.uploading-document{
    border-radius: 6px;
    z-index: 999999;
}