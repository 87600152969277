.emojiBoxOpen{
    z-index: 9;
    &.emojiRight{
        bottom: -41px;
        right: 24px;
    }
}

.group-name-input-wrap{
    margin:1rem 0.5rem 2rem 0.5rem;
    display: flex;
    & .group-name-input{
        width:100%;
        & .MuiInputBase-root{
            margin-right:0.1rem;
            & .MuiInputBase-input{
                height:4px;
            }
        }
    }
}
.group-member-chip{
    display: flex;
    flex-wrap: wrap;
    gap:4px;
    margin:0.5rem;
}
.group-member-input{
    // margin:1rem;
    margin:0.1rem 0.5rem 2rem 0.5rem;
    // padding-right: 12px;
    & .MuiInputBase-root{
        width: 100%;
        // height:0.5rem;
        & .MuiSelect-select{
            padding-top:8px;
            padding-bottom: 8px;
        }
    }
}
.MuiFormControl-root.group-invitation-input{
    width:97%;
    margin:0.1rem 0.5rem;
    & .MuiInputBase-root{
    }
}
.errorgrouperror{
    color:red;
}