.users-list-body p.lastmessage {
  word-break: break-all;
  width: 75%;
}

.users-list-action {
  height: 100%;
  display: flex;
  padding-top: "0px";
  flex-direction: column !important;
  justify-content: start !important;
  .action-toggle {
    display: none !important;
  }
}

.users-list-body {
  &:hover {
    .action-toggle {
      display: block !important;
    }
  }
}
